import './style.css'
import Entry from './Entry';

export default function Loading(){

  return <>
    <div className="container">
      <Entry/>
    </div>
  </>
}