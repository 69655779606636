const LoadingMessages = [
    "Reticulating splines...",
    "Generating witty dialog...",
    "Swapping time and space...",
    "Spinning violently around the y-axis...",
    "Don't think of purple hippos...",
    "We need a new fuse...",
    "Upgrading Windows, your PC will restart several times. Sit back and relax.",
    "Would you prefer chicken, steak, or tofu?",
    "We're testing your patience",
    "As if you had any other choice",
    "Follow the white rabbit",
    "Why don't you order a sandwich?",
    "It's still faster than you could draw it",
    "Have you lost weight?",
    "Just count to 10",
    "Why so serious?",
    "It's not you. It's me.",
    "Laughing at your pictures-i mean, loading...",
    "Winter is coming...",
    "It's 10:00pm. Do you know where your children are?",
    "Mining some bitcoins...",
    "Downloading more RAM..",
    "Updating to Windows Vista...",
    "Deleting System32 folder",
    "Alt-F4 speeds things up.",
    "Please wait while the minions do their work",
]

export default LoadingMessages